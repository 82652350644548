// import { faDumpster } from '@/node_modules/@fortawesome/free-solid-svg-icons/index'
import axios from '@utils/client'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    collaborators: [],
    collaborator: {},
    iconLoading: true,
    notifications: [],
    collaboratorsByRpAndSite: [],
    validators: [],
    collAssignSitesSigner: [],
    collaboratorPlanning: [],
    collaboratorsToValidateIntegrationsRps: [],
  }
}

export const mutations = {
  updateCollaboratorsToValidateIntegrationsRps(state, collaboratorsToValidateIntegrationsRps){
    state.collaboratorsToValidateIntegrationsRps = collaboratorsToValidateIntegrationsRps
  },
  updateCollaborators(state, collaborators) {
    state.collaborators = collaborators
  },
  updateCollaborator(state, collaborator) {
    state.collaborator = collaborator
  },
  resetCollaborator() {
    state.collaborator = () => {}
  },
  updateNotifications(state, notifications) {
    state.notifications = notifications
  },
  updateCollaboratorsByRpAndSite(state, collaboratorsByRpAndSite) {
    state.collaboratorsByRpAndSite = collaboratorsByRpAndSite
  },
  updateCollaboratorsBySiteAndRole(state, validators) {
    state.validators = validators
  },
  updateCollaboratorAssignSitesSigner(state, collAssignSitesSigner) {
    state.collAssignSitesSigner = collAssignSitesSigner
  },
  updateCollaboratorsPlanning(state, collaboratorPlanning) {
    state.collaboratorPlanning = collaboratorPlanning
  },
}

export const state = getDefaultState()

export const actions = {
  // COLLABORATOR
  // GET
  async fetchCollaborator(store, username) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaborators.show/${username}`
      const response = await axios.get(url)
      store.commit('updateCollaborator', response.data)

      store.commit('common/updateIconLoading', false, { root: true })
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },
  // COLLABORATORS
  // GET
  async fetchCollaborators(store, playload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      let url = ''

      if (playload.site !== undefined) {
        url = `${BASE}/api/v1/collaborators.index?site=` + playload.site
      } else {
        url = `${BASE}/api/v1/collaborators.index`
      }

      const response = await axios.get(url)
      store.commit('common/updateIconLoading', false, { root: true })
      store.commit('updateCollaborators', response)
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },

  // EPLANNING COLLABORATOR
  // GET
  async fetchEPlanningCollaborator(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaborators.getEPlanningCollaborator?filter=${payload.key}&searchText=${payload.value}`
      const response = await axios.get(url)
      store.commit('common/updateIconLoading', false, { root: true })
      store.commit('updateCollaborator', response.data)
      return response
    } catch (error) {
      throw new Error('API Error occurred at api/collaborators.')
    }
  },

  // NEW COLLABORATOR
  // POST
  async collaboratorNew(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/collaborators.create`,
        payload
      )
      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/collaborators')
    }
  },
  // SEARCH COLLABORATOR
  // POST
  async searchCollaboratorDB(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })
      const response = await axios.post(
        `${BASE}/api/v1/collaborators.search?key=${payload.key}&search=${payload.value}`
      )
      store.commit('updateCollaborators', response)
      store.commit('common/updateIconLoading', false, { root: true })
      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/collaborators')
    }
  },

  // UNSUBSCRIBE COLLABORATOR
  // POST
  async unsubscribeCollaboratorDB(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/collaborators.unsubscribe`,
        payload
      )

      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/collaborators')
    }
  },

  // Get notification by collaborator
  // GET
  async getCollaboratorNotifications(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.get(
        `${BASE}/api/v1/notification.byCollaborator/${payload}`
      )

      store.commit('updateNotifications', response.data)

      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/collaborators')
    }
  },
  // Set notification read true
  // POST
  async updateReadDB(store, payload) {
    try {
      const response = await axios.get(
        `${BASE}/api/v1/notification.setRead/${payload}`
      )

      return response.data.read
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/notification.setRead')
    }
  },
  // get collaborator signature
  // POST
  async getSignature(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(`${BASE}/api/v1/signature.get`, payload)

      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/notification.setRead')
    }
  },
  // update collaborator signature
  // POST
  async updateSignature(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/collaborators.signature`,
        payload
      )

      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/notification.setRead')
    }
  },
  // request prevent register collaborator signature
  // POST
  async signDocumentDB(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/signature.sing`,
        payload
      )

      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/signature')
    }
  },
  // request prevent register collaborator signature
  // POST
  async refreshSignedDocumentDB(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/signature.refreshDocument`,
        payload
      )

      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/signature')
    }
  },
  // UPDATE COLLABORATOR ON DATA BASE
  async updateCollaboratorDB(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const url = `${BASE}/api/v1/collaborators.update`

      const response = await axios.post(url, payload, {
        timeout: 0,
      })

      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (error) {
      throw Error('API Error ocurred at post update')
    }
  },

  // Get collaborators by Rp status pending and site
  async fetchCollaboratorsBySite(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/collaborators.byRpAndSite`,
        payload
      )

      store.commit('updateCollaboratorsByRpAndSite', response.data)
      store.commit('common/updateIconLoading', false, { root: true })

      return response
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },

  // Get collaborators by site and Role
  async fetchCollaboratorsBySiteAndRole(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/collaborators.bySiteAndRole`,
        payload
      )

      store.commit('updateCollaboratorsBySiteAndRole', response.data)
      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },

  // Set o remove collaborator Assign Sites Signer
  // POST
  async collaboratorAssignSitesSigner(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/collaborator.assignSiteSigner`,
        payload
      )
      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/api/v1/collaborator ${error}`
      )
    }
  },

  // GET SITES ALL ENABLED
  // GET
  async getCollaboratorAssignSitesSigner(store, username) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.get(
        `${BASE}/api/v1/collaborator.getAssignSiteSigner/${username}`
      )
      store.commit('updateCollaboratorAssignSitesSigner', response.data)
      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/api/v1/collaborator ${error}`
      )
    }
  },
  // GET PLANNING BY DATE AND SITE
  async fetchCollaboratorsGlobalPlanning(store, payload) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.post(
        `${BASE}/api/v1/collaborators.planning`,
        payload
      )

      store.commit('updateCollaboratorsPlanning', response.data)
      store.commit('common/updateIconLoading', false, { root: true })

      return response.data
    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },

  async fetchCollaboratorsToValidateIntegrationsRps(store) {
    try {
      store.commit('common/updateIconLoading', true, { root: true })

      const response = await axios.get(
          `${BASE}/api/v1/collaborators.toValidateIntegrationsRps`
      )

      store.commit('updateCollaboratorsToValidateIntegrationsRps', response.data)
      store.commit('common/updateIconLoading', false, { root: true })

    } catch (e) {
      throw Error('API Error ocurred at post api/v1/rps')
    }
  },

}
